<template>
	<div class="modal-body">
		<!-- NOMBRE -->
		<div class="row">
			<div class="col-md-12 form-group">
				<label class="col-form-label label-modal" for="reason">Motivo</label>
				<textarea class="form-control text-uppercase" rows="3" v-model="reason" name="reason" v-validate="'required'" data-vv-as="'Motivo'"></textarea>
				<span v-show="errors.has('reason')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('reason') }} </span>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			reason: '',
			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update', 'execute_commit']),
		mtd_onCreate() {
			this.$emit('onload', { status: 'success' });
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
		mtd_store: function () {
			this.$swal
				.fire({
					title: `¿Esta seguro que desea eliminar el requerimiento?`,
					text: `${this.propInfo.data.row.requirement_number}`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: `${this.propInfo.data.row.enabled == 1 ? '#e74c3c' : '#28a745'}`,
					confirmButtonText: `Si, eliminar`,
				})
				.then(result => {
					if (result.isConfirmed) {
						this.update({
							url: `${this.url_api}/requirements/${this.propInfo.data.row.id}/deleted`,
							params: {
								reason: this.reason,
							},
						}).then(res => {
							if (res.state == 'success') {
								this.$emit('success', { component: this.propInfo.component, res: { requirement: this.propInfo.data.row }, action: 'store', modal_id: this.modal_id });
								window.message('success', 'Eliminación exitoso');
							}
						});
					}
				});
		},
	},
};
</script>
